export const step3Validation = (onSuc, onFail) => {
	return {
		on: 'blur',
		onSuccess: onSuc,
		onFailure: onFail,
		fields: {
			name: {
				identifier: 'name',
				rules: [
					{
						type: 'empty',
						prompt: 'Vyplňte prosím jméno a příjmení'
					}
				]
			},
			email: {
				identifier: 'email',
				rules: [
					{
						type: 'empty',
						prompt: 'Vyplňte prosím email'
					},
					{
						type: 'email',
						prompt: 'Váš email není ve správném tvaru'
					},
				]
			},
			phone: {
				identifier: 'phone',
				rules: [
					{
						type: 'empty',
						prompt: 'Vyplňte prosím telefon'
					}
				]
			},
			agree: {
				identifier: 'agree',
				rules: [
					{
						type: 'checked',
						prompt: 'Musíte souhlasit s našimi obchodními podmínkami'
					}
				]
			}
		}
	}
};