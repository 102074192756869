import {step3Validation} from './validations/step3Validation';
import {step2Validation} from "./validations/step2Validation";
import {variant1Validation} from "./validations/variant1Validation";
import {variant2Validation} from "./validations/variant2Validation";
import {variant3Validation} from "./validations/variant3Validation";
import {blankValidation} from "./validations/blankValidation";

let variantDetail = 0;
let variantStyle = 0;
let currentStep = 1;
let disableddates = [];


$(document).ready(function () {

	setStep(currentStep);

	$('.dropdown').dropdown();
	$('.ui.radio.checkbox').checkbox();
	$('.coloring').click(function () {
		const color = $(this).css('background-color');
		$(this).parents('.dropdown').css('background-color', color);
		$(this).parents('.field').find('input').val($(this).text());
		$(this).parents('.field').removeClass('error');
	});

	/**
	 * STEPS HANDLING
	 */
	// Bottom navigation
	$('.step').click(function () {
		const link = $(this).data('link');
		if (link < getCurrentStep()) {
			setStep(link)
		} else {
			const elementToValidate = getCurrentStepElement();
			refreshValdiationRules(() => setStep(link), () => console.log("FDSAf"));
			$(elementToValidate).form('validate form');
		}
	});

	// buttons next step
	$('.next-step').click(function (e) {
		e.preventDefault();
		const link = $(this).data('link');
		const elementToValidate = getCurrentStepElement();
		console.log("next step clicked");
		refreshValdiationRules(() => setStep(link), () => console.log("FDSAf"));
		$(elementToValidate).form('validate form');
	});


	/**
	 * Variant handling
	 */

	//DETAIL
	//$('.variant').hide();
	$('.service-variant').click(function () {
		const selected = $(this).data('service-variant');
		setDetailVariant(selected);
		refreshValdiationRules(() => console.log('ok'));
		console.log($('#service-variant-input'));
		$('#service-variant-input').val(selected);
	});

	// STYLE
	//$('.variant-s').hide();
	$('.style-variant').click(function () {
		const selected = $(this).data('style-variant');
		setStyleVariant(selected);
		refreshValdiationRules(() => console.log('ok'));
		$('#style-variant-input').val(selected);
	});

	/**
	 * Datepicker
	 */
	/*lokalizace do češtiny*/
	$.datepicker.regional['cs'] = {
		closeText: 'Zavřít',
		prevText: 'Předchozí',
		nextText: 'Další',
		currentText: 'Hoy',
		monthNames: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
		monthNamesShort: ['Le', 'Ún', 'Bř', 'Du', 'Kv', 'Čn', 'Čc', 'Sr', 'Zá', 'Ří', 'Li', 'Pr'],
		dayNames: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
		dayNamesShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
		dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
		weekHeader: 'Sm',
		dateFormat: 'dd.mm.yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''
	};
	$.datepicker.setDefaults($.datepicker.regional['cs']);


	/**
	 * Decision logic for the first available date
	 */
	getServerTime()
		.then((date) => {
			console.log(date);
			let minDate = 1;
			disableddates = getFreeDays(date.year);
			const currentDay = `${date.mon}-${date.mday}-${date.year}`;
			console.log(currentDay);
			// Logic
			if (date.hours > 14)
				minDate += 1;

			if (date.weekday === "Saturday")
				minDate += 1;

			if (date.weekday === "Sunday") {
				minDate += 1;
				if (date.hours > 14)
					minDate -= 1;
			}

			$('#datepicker').datepicker({
				beforeShowDay: DisableSpecificDates,
				minDate,
				onSelect: () => {
					$('#datepicker').parents('.field').removeClass('error')
				}
			});
		});

	/**
	 * Validations file handeling (presence testing)
	 */
	$('#thesisFile').change(function () {
		$('#thesisFilePresent').val($(this).val());
	});

	$('#bookFileUpload').change(function () {
		$('#bookFilePresent').val($(this).val());
	});

	/**
	 * Validations
	 */

	// STEP 1


	// STEP 3

	$('#step-3').form(step3Validation(() => {
		$('form').submit();
		$('#send-it').addClass('loading');
	}));

	$('#send-it').click(function (e) {
		e.preventDefault();
		console.log('send clicked');
		$('#step-3').form('validate form');
		//sendData({test: "test"})
	});

	// FILES
	$('[input="file"]').change(function () {
		console.log(validateFile($(this)));
	});

	/**
	 * Schovavani u typu vazeb
	 */
	$('.booking').click(function () {
		$(this).parents('.fields').find('.field:nth-child(2)').toggleClass('c-hidden');
	});


});


const refreshValdiationRules = (onSuccess, onFailure) => {
	const variant = getCurrentVariant();
	const step = getCurrentStep();
	console.log("inside");
	console.log("step " + step);
	console.log("variant " + variant);
	// podminky pro validaci
	if (step === 1) {
		if (variant === 1)
			$('#step-1').form(variant1Validation(onSuccess, onFailure));
		if (variant === 2)
			$('#step-1').form(variant2Validation(onSuccess, onFailure));
		if (variant === 3)
			$('#step-1').form(variant3Validation(onSuccess, onFailure));
	}
	if (step === 2) {
		if (variant === 1) {
			$('#step-2').form(step2Validation(onSuccess, onFailure));
		}
		if (variant === 2) {
			console.log("tohle je ono");
			$('#step-2').form(blankValidation(onSuccess, onFailure));
		}
	}
};


const setDetailVariant = (variant) => {
	$('.variant').hide();
	$('.variant-all').fadeIn();
	$(`.variant-${variant}`).fadeIn();
	variantDetail = variant;
	console.log("SERVICE VARIANT SET TO " + variant);
};

const getCurrentVariant = () => {
	switch (currentStep) {
		case 1:
			return variantDetail;
		case 2:
			return variantStyle;
	}
	return 0;
};

const setStyleVariant = variant => {
	$('.variant-s').hide();
	$('.variant-s-all').fadeIn();
	$(`.variant-s-${variant}`).fadeIn();
	variantStyle = variant;
};

const validateFileInput = () => {
	console.log("inside");
	const currentStep = getCurrentStepElement();
	const fileInput = currentStep.find('[type="file"]:visible');
	const result = validateFile(fileInput);
	const errorElement = currentStep.find('.message .list');
	// nalezen problem
	if (result.error) {
		console.log(result.prompts);
		result.prompts.forEach((value, index) => {
			errorElement.append(`<li>${value}</li>`)
		})
	}
};

const setStep = step => {
	const stepElement = $('#step-' + step);
	$('.stp').hide();
	stepElement.fadeIn();
	$('#bottom-menu .step').removeClass('active');
	$('#bottom-menu').find(`[data-link="${step}"]`).addClass('active');
	currentStep = step;
};

const getCurrentStep = () => currentStep;

const getCurrentStepElement = () => {
	return $(`#step-${getCurrentStep()}`);
};

/**
 * DATES FUNCTIONS
 */
const DisableSpecificDates = (date) => {

	const m = date.getMonth();
	const d = date.getDate();
	const y = date.getFullYear();

	// First convert the date in to the mm-dd-yyyy format
	// Take note that we will increment the month count by 1
	const currentdate = (m + 1) + '-' + d + '-' + y;

	// We will now check if the date belongs to disableddates array
	for (let i = 0; i < disableddates.length; i++) {

		// Now check if the current date is in disabled dates array.
		if ($.inArray(currentdate, disableddates) !== -1) {
			return [false];
		}
	}

	// In case the date is not present in disabled array, we will now check if it is a weekend.
	// We will use the noWeekends function
	return $.datepicker.noWeekends(date);

};

const getServerTime = () => {
	return new Promise((resolve, reject) => {
		fetch('/api/v1/time', {
			method: 'get',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then(res => res.json())
			.then(res => resolve(res))
			.catch(err => reject(err))
	});
};

const getFreeDays = (cY) => {
	return [
		`1-1-${cY}`,
		`5-1-${cY}`,
		`1-1-${cY}`,
		`5-8-${cY}`,
		`7-5-${cY}`,
		`7-6-${cY}`,
		`9-28-${cY}`,
		`10-28-${cY}`,
		`11-17-${cY}`,
		`12-24-${cY}`,
		`12-25-${cY}`,
		`12-26-${cY}`
	]
};


