export const variant3Validation = (onSuc, onFail) => {
	return {
		on: 'blur',
		onSuccess: onSuc,
		onFailure: onFail,
		fields: {
			date: {
				identifier: 'date',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím datum, kdy si chcete práci vyzvednout'
				}]
			},
			thesisFile: {
				identifier: 'thesisFile',
				optional: true,
				rules: [
					{
						type: 'fileSize[thesisFile]',
						prompt: 'Velikost souboru je moc velká'
					},
					{
						type: 'fileFormat[thesisFile]',
						prompt: 'Nedovolený formát souboru, přiložte prosím pdf'
					}
				]
			},
			thesisFilePresent: {
				identifier: 'thesisFilePresent',
				rules: [
					{
						type:'empty',
						prompt: 'Není přiložen žádný soubor'
					}
				]
			},
		}
	}
};