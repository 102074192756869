const validateExtensionWithDoc = (fileElement) => {
	const parts = fileElement[0].value.split(".");
	const extension = parts[parts.length - 1].toLowerCase();
	return extension === 'pdf' || extension === 'doc' || extension === 'docx';
};

$.fn.form.settings.rules.fileFormatWithDoc = function (value, fileElement) {
	console.log("inside");
	return validateExtensionWithDoc($('#' + fileElement))
};

export const step2Validation = (onSuc, onFail) => {
	return {
		on: 'blur',
		onSuccess: onSuc,
		onFailure: onFail,
		fields: {
			bookFileUpload: {
				identifier: 'bookFileUpload',
				optional: true,
				rules: [
					{
						type: 'fileSize[bookFileUpload]',
						prompt: 'Velikost souboru je příliš velká'
					},
					{
						type: 'fileFormatWithDoc[bookFileUpload]',
						prompt: 'Nepovolený formát souboru, přiložte prosím soubor typu pdf, doc nebo docx'
					}
				]
			},
			bookFilePresent: {
				identifier: 'bookFilePresent',
				rules: [
					{
						type: 'empty',
						prompt: 'Není přiložen žádný soubor'
					}
				]
			},
		}
	}
};