export const variant2Validation = (onSuc, onFail) => {
	return {
		on: 'blur',
		onSuccess: onSuc,
		onFailure: onFail,
		fields: {
			countOfPrints: {
				identifier: 'countOfPrints',
				rules: [{
					type: 'empty',
					prompt: 'Vyplňte prosím počet výtisků'
				},
					{
						type: 'integer',
						prompt: 'Počet výtisků musí být celé číslo'
					}
				]
			},
			bookColor: {
				identifier: 'bookColor',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím barvu desek'
				}]
			},
			bookTextColor: {
				identifier: 'bookTextColor',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím barvu potisku desek'
				}]
			},
			date: {
				identifier: 'date',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím datum, kdy si chcete práci vyzvednout'
				}]
			},
			pageCount: {
				identifier: 'pageCount',
				optional: true,
				rules: [{
					type: 'integer',
					prompt: 'Počet stránek musí být celé číslo'
				}]
			},
			colorPageCount: {
				identifier: 'colorPageCount',
				optional: true,
				rules: [{
					type: 'integer',
					prompt: 'Počet barevných stránek musí být celé číslo'
				}]
			}
		}
	};
};