const validateSize = (fileElement) => {
	console.log(fileElement);
	for (let i = 0; i < fileElement[0].files.length; i++) {
		console.log(fileElement[0].files[i]);
		if (fileElement[0].files[i].size / 1024 / 1024 > 12) {
			return false;
		}
	}
	return true;
};

const validateExtension = (fileElement) => {
	const parts = fileElement[0].value.split(".");
	return parts[parts.length - 1].toLowerCase() === 'pdf';

};

$.fn.form.settings.rules.fileSize = function (value, fileElement) {
	console.log("inside");
	return validateSize($('#' + fileElement))
};

$.fn.form.settings.rules.fileFormat = function (value, fileElement) {
	console.log("inside");
	return validateExtension($('#' + fileElement))
};

export const variant1Validation = (onSuc, onFail) => {
	return {
		on: 'blur',
		onSuccess: onSuc,
		onFailure: onFail,
		fields: {
			thesisFile: {
				identifier: 'thesisFile',
				optional: true,
				rules: [
					{
						type: 'fileSize[thesisFile]',
						prompt: 'Velikost souboru je moc velká'
					},
					{
						type: 'fileFormat[thesisFile]',
						prompt: 'Nedovolený formát souboru, přiložte prosím pdf'
					}
				]
			},
			thesisFilePresent: {
				identifier: 'thesisFilePresent',
				rules: [
					{
						type: 'empty',
						prompt: 'Není přiložen žádný soubor'
					}
				]
			},
			countOfPrints: {
				identifier: 'countOfPrints',
				rules: [{
					type: 'empty',
					prompt: 'Vyplňte prosím počet výtisků'
				},
					{
						type: 'integer',
						prompt: 'Počet výtisků musí být celé číslo'
					}
				]
			},
			bookColor: {
				identifier: 'bookColor',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím barvu desek'
				}]
			},
			bookTextColor: {
				identifier: 'bookTextColor',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím barvu potisku desek'
				}]
			},
			date: {
				identifier: 'date',
				rules: [{
					type: 'empty',
					prompt: 'Vyberte prosím datum, kdy si chcete práci vyzvednout'
				}]
			}
		}
	}
};